<script setup lang="ts">
const props = defineProps<{
	name: string
	label?: string
	severity?: 'primary' | 'secondary' | 'tertiary' | 'error'
	animated?: boolean
	static?: boolean
}>();

const shape = defineAsyncComponent(async () => {
	const component = await import(`assets/images/shapes/shape-${props.name.toLocaleLowerCase()}.svg`);
	return component;
});
</script>

<template>
	<span
		class="shape"
		:class="[{
			[`shape--${props.severity}`]: props.severity,
			[`shape--${props.label}`]: props.label,
			'shape--animated': props.animated,
			'shape--static': props.static,
		}]"
		aria-hidden="true"
	>
		<component class="shape__item" :is="shape" />
	</span>
</template>
